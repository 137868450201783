import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';

import './libs/countUp.lib.js';

/* SWIPERS */
import './components/swiper-home-services.component.js';
import './components/swiper-home-team.component.js';
import './components/swiper-home-testimonials.component.js';

const telInputs = document.querySelectorAll('input[type="tel"]');

telInputs.forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
  });
});

/* Scroll To Section */
document.querySelectorAll('a[href*="#"]').forEach((button) => {
  button.addEventListener('click', function (e) {
    const href = this.getAttribute('href');
    const targetId = href.split('#').pop();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      e.preventDefault();
      const headerHeight = 108;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  });
});
