import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from './swiper.utils';

export const swiperOnResize = (
  breakpoint,
  swiperClass,
  swiperSettings,
  startOnView
) => {
  let swiper;

  breakpoint = window.matchMedia(breakpoint);

  const initSwiper = function (className, settings) {
    swiper = new Swiper(className, settings);

    if (startOnView) {
      swiperObserver(swiper);
    }
  };

  const checker = function () {
    if (breakpoint.matches) {
      return initSwiper(swiperClass, swiperSettings);
    } else {
      if (swiper !== undefined) swiper.destroy(true, true);
      return;
    }
  };

  breakpoint.addEventListener('change', checker);
  checker();
};

/* Usage *?
import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';

let newsSwiperWr = document.querySelectorAll('.news-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 'auto',
          spaceBetween: 8,
          speed: 800,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
          },
        },
    );
  }
}); */
