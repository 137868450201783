import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils';

let newsSwiperWr = document.querySelectorAll('.home-services-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    swiperOnResize('(max-width: 801px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 24,
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});
